import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import '../styles/style.scss';
import Header from './header'
import Footer from './footer';

const Layout = ({children, title}) => (
  <>
    <Helmet
      title={title}
      meta={[
        {name: 'description', content: title},
        {name: 'keywords', content: title},
      ]}
    >
      <html lang="en"/>
      <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-56a72f87fa37f9a5" async="async"></script>
    </Helmet>

    <section className="section-main">
      <Header/>

      <section className="section-layout-v2">
        <div id="page-wrapper">

          <section id="banner">
            <div className=" container">

              <div className="large-shadow-text">
                <h2 className="line-main">{title}</h2>
              </div>

            </div>
          </section>

          <section id="wrapper">
            <section className="wrapper stylelast">
              <div className="container-layout-v2">
                {children}
              </div>
            </section>
          </section>

        </div>

      </section>

      <Footer/>
    </section>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
