import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

    <Layout>
        <section className="section-home">

            <div id="root">
                <div className="ae af">


                    <main id="main">
                        <div
                            className="wcb3-billboard-cta b5 b6 b7 b8 b9 ba bb ae bx be jg bg bh bi bj c0 bl ji bn c2 bp jk br">
                            <div className="bj bs bt">
                                <h1>Insurance & Licensing</h1>
                                <p>For Passenger Carriers in the U.S. and Canada</p>
                                <p>All charter bus operators that are BellMe affiliate will be able to provide
                                    appropriate licensing and insurance information upon request. The documents can be a
                                    .PDF or fax.</p>
                                <p><strong>IMPORTANT: </strong>USA INTERSTATE operators can cross state lines; USA
                                    INTRASTATE operators
                                    cannot leave the state. Canadian operators have different guidelines.</p>
                                <p><strong>USA Interstate Operators</strong></p>
                                <p>Passenger carrier operating authority and licensing and insurance requirements in the
                                    United States are authorized by the Federal Motor Carrier Safety Administration
                                    (FMCSA). Each operator must register a USDOT number and obtain a minimum of $5
                                    million insurance. Limo, Taxi and Gig drivers are required to have a minimum
                                    $300,000 policy. BellMe will offer an additional $1 Million rider policy
                                    while the client is being transported by one or those drivers.
                                    The Operating Authority Guide (accessible by clicking the image above) includes an
                                    overview of licensing and insurance requirements, including safety results and other
                                    aspects of the FMCSA’s SAFER Company Snapshot.
                                </p>
                                <p><strong>USA Intrastate Operators</strong></p>
                                <p>Intrastate operators may not require a USDOT registration. The FMCSA provides
                                    information state which states are exempt from a USDOT registration.
                                </p>
                                <p><strong>Canada Passenger Operators</strong></p>
                                <p>Each Canadian Province or Territory regulates licensing and insurance for each
                                    passenger carrier. Depending on provincial regulations, some Canadian operators are
                                    only permitted to pick up groups to and from specific areas where permits allow.
                                    A Carrier Abstract can be requested from the operator or governing department of
                                    operator regarding the safety record of that Canadian company. The below links will
                                    state each province and territories transportation departments statistics.</p>
                                <p><strong>Province and Territory Transportation Links
                                </strong></p>
                                <p><a href="">Alberta Transportation</a></p>
                                <p><a href="">British Columbia Ministry of Transportation and Infrastructure</a></p>
                                <p><a href="">Manitoba Infrastructure and Transportation</a></p>
                                <p><a href="">New Brunswick Transportation and Infrastructure</a></p>
                                <p><a href="">Newfoundland Department of Transportation and Works</a></p>
                                <p><a href="">Northwest Territories Transportation</a></p>
                                <p><a href="">Nova Scotia Transportation and Infrastructure</a></p>
                                <p><a href="">Nunavut Department of Economic Development and Transportation</a></p>
                                <p><a href="">Ontario Ministry of Transportation</a></p>
                                <p><a href="">Prince Edward Island Transportation and Infrastructure</a></p>
                                <p><a href="">Quebec Ministry of Transportation</a></p>
                                <p><a href="">Saskatchewan SGI</a></p>
                                <p><a href="">Yukon Highways and Public Works</a></p>
                                <p>Call or click If you have any other questions regarding licensing, insurance or
                                    operating authority.
                                    Contact BellMe at info@bellme.xyz</p>
                            </div>
                            <br/>

                        </div>
                    </main>
                </div>
            </div>
        </section>
    </Layout>
);

export default Page;
